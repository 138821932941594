import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  public currentVersion: string = require('../../../../assets/version.json')
    .version;
  private showMenu$ = new BehaviorSubject<boolean>(false);

  public get showMenu() {
    return this.showMenu$.value;
  }

  public set showMenu(value: boolean) {
    this.showMenu$.next(value);
  }

  public showMenuObservable: Observable<boolean>;

  constructor() {
    this.showMenuObservable = this.showMenu$.asObservable();
  }
}
