import { Injectable } from '@angular/core';
import { AlertModalI } from '@app/shared/models/api/alert.interface';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  alertData$: BehaviorSubject<AlertModalI> = new BehaviorSubject(null);
  btnTypes = {
    primary: 'btn btn-primary',
    tertiary: 'btn btn-tertiary',
  };
  constructor() {}

  createAlert(data: AlertModalI): void {
    this.alertData$.next(data);
  }
  get showAlert(): AlertModalI {
    return this.alertData$.getValue();
  }
  closeAlert(): void {
    this.alertData$.next(null);
  }
}
