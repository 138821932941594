<div
  *ngIf="alertModalData"
  class="w-screen h-screen fixed inset-0 bg-black/[0.4] z-[1000] grid place-items-center"
>
  <div
    class="w-full md:w-5/12 rounded-lg bg-white h-auto flex flex-col items-center p-10 gap-y-10"
  >
    <div>
      <svg-icon
        svgClass="h-32 w-32 text-primary"
        [name]="alertModalData.icon || 'timesSquare'"
      ></svg-icon>
    </div>
    <h2 class="text-black font-alternate text-4xl leading-[38px]">
      {{ alertModalData.title }}
    </h2>
    <p class="text-black font-primary font-normal text-lg text-center">
      {{ alertModalData.message }}
    </p>
    <div class="w-full flex items-center justify-center gap-x-3">
      <button
        *ngFor="let item of alertModalData.buttons"
        (click)="runCallBack(item.action)"
        class="w-1/2"
        [ngClass]="[getBtnClasses(item.type)]"
      >
        {{ item?.text }}
      </button>
    </div>
  </div>
</div>
