import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import localforage from 'localforage';
import { MenuService } from '../menu/menu.service';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  private storeName = 'optimerix_admin';
  private store?: Record<string, unknown>;

  constructor(private router: Router, private menuS: MenuService) {
    this.initStore();
  }

  private async initStore() {
    try {
      localforage.config({
        name: this.storeName + '__store',
      });
      const _store = await localforage.getItem<typeof this.store>(
        this.storeName,
      );
      if (!_store) {
        const data = {};
        await localforage.setItem(this.storeName, data);
      }
      this.store = await this.getFromStorage(this.storeName);
    } catch (error) {
      console.error(error);
    }
  }

  private async writeToStorage(): Promise<Record<string, unknown> | void> {
    if (this.store) {
      return localforage.setItem(this.storeName, this.store);
    }
  }

  private async getFromStorage<T>(key: string): Promise<T> {
    const value = await localforage.getItem<T>(key);
    if (value == null) {
      throw new Error('Error: No data with the specified key exists.');
    }
    return value;
  }

  public async get<T>(key: string): Promise<T> {
    if (!this.store) {
      await this.initStore();
    }
    return this.store![key] as T;
  }

  public async clearStorage(): Promise<void> {
    await localforage.removeItem(this.storeName);
    this.store = {};
    this.menuS.showMenu = false;
    this.router.navigate(['/login']);
    // window.location.reload();
  }

  public async set(key: string, value: unknown): Promise<void> {
    try {
      if (!this.store) {
        await this.initStore();
      }
      this.store![key] = value;
      this.writeToStorage();
    } catch (error) {
      console.error(error);
    }
  }
}
