import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import get from 'lodash/get';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  constructor() {}

  public getConfig<T>(key: string): T | null {
    const config = environment;
    return get(config, key);
  }

  public getServerUrl(): string {
    return this.getConfig('serverUrl') as string;
  }

  public getWebAppVersion(): string {
    return this.getConfig('webAppVersion') as string;
  }
}
