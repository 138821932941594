import { Dialog } from '@angular/cdk-experimental/dialog';
import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Roles } from '@app/core/enum/roles.enum';
import { AssociationStatus } from '@app/shared/enums/association-status';
import {
  CompanyInfo,
  LoginRequestBody,
  LoginResponse,
  SignupRequestBody,
  UserProfile,
} from '@app/shared/models/api';
import { LoginUserI } from '@app/shared/models/api/user.interface';
import { JwtHelperService } from '@auth0/angular-jwt';
import {
  BehaviorSubject,
  Observable,
  Subscription,
  delay,
  distinctUntilChanged,
  filter,
  map,
  of,
  tap,
} from 'rxjs';
import { ConfigService } from '../config/config.service';
import { LanguagesService } from '../languages/languages.service';
import { NotificationService } from '../notification/notification.service';
import { RequestService } from '../request/request.service';
import { StorageService } from '../storage/storage.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  tokenSubscription = new Subscription();
  timeout: number;
  private endpoint: string;
  private companyEndpoint: string;

  private user$: BehaviorSubject<{
    init: boolean;
    data: LoginUserI;
  }> = new BehaviorSubject(null);
  private loginDetails$: BehaviorSubject<{
    init: boolean;
    data: LoginResponse;
  }> = new BehaviorSubject(null);
  emailActivationLink =
    this.location?.['_platformLocation']?.['location']?.origin +
    '/' +
    this.langS.appLanguage +
    '/verificare';

  constructor(
    private configS: ConfigService,
    private reqS: RequestService,
    private storageS: StorageService,
    private notificationS: NotificationService,
    private jwtHelper: JwtHelperService,
    private location: Location,
    private langS: LanguagesService,
    private readonly dialog: Dialog,
  ) {
    this.endpoint = this.configS.getServerUrl() + '/WebUser/';
    this.companyEndpoint =
      this.configS.getServerUrl() + '/WebPharmacy/ReadCompanyInfo';
    (async () => {
      try {
        const loginUserDetails: LoginResponse = await this.storageS.get('user');
        if (loginUserDetails !== null) {
          this.user$.next({
            init: true,
            data: loginUserDetails?.user,
          });
          this.loginDetails$.next({
            init: true,
            data: loginUserDetails,
          });
        } else {
          this.notificationS.error('Vă rugăm să vă autentificați din nou');
          this.logout();
        }
      } catch (error) {
        this.notificationS.error(
          'Eroare! vă rugăm să încercați să vă conectați din nou',
        );
        this.logout();
      }
    })();
  }

  public async getToken(): Promise<string | void> {
    const user = (await this.storageS.get('user')) as LoginResponse | null;
    if (!user || !user?.token) {
      return;
    }
    if (user && user?.token) {
      this.checkIfTokenIsExpired(user.token);
    }
    const { token } = user;
    return `Bearer ${token}`;
  }

  public isLoggedIn(): boolean {
    let loggedInState: boolean;
    const token = this.loginDetails$.getValue()?.data?.token || '';
    if (!token || token.length === 0) {
      loggedInState = false;
    }
    loggedInState = true;
    return loggedInState;
  }

  public loginByEmail(data: LoginRequestBody): Observable<LoginResponse> {
    return this.reqS.post(this.endpoint + 'LoginByEmail', data).pipe(
      tap(async (v: LoginResponse) => {
        this.user$.next({
          init: true,
          data: v?.user,
        });
        this.loginDetails$.next({
          init: true,
          data: v,
        });
        // this.checkIfTokenIsExpired(v.token); //

        await this.storageS.set('user', {
          ...v,
        });
      }),
    );
  }

  checkIfTokenIsExpired(tokenDate) {
    if (tokenDate) {
      this.timeout =
        this.jwtHelper.getTokenExpirationDate(tokenDate).valueOf() -
        new Date().valueOf();
      this.expirationCounter(this.timeout);
    } else {
      this.logout();
    }
  }

  expirationCounter(timeout: number) {
    this.tokenSubscription.unsubscribe();
    this.tokenSubscription = of(null)
      .pipe(delay(timeout))
      .subscribe((expired) => {
        this.logout();
      });
  }

  public recoverPassword(data: any): Observable<any> {
    return this.reqS.post(this.endpoint + 'ForgotPassword', data);
  }
  public resetPassword(data: {
    email: string;
    password: string;
    token: string;
  }): Observable<any> {
    return this.reqS.post(this.endpoint + 'ResetPassword', data);
  }

  public signupByEmail(data: SignupRequestBody): Observable<any> {
    return this.reqS.post(this.endpoint, data);
  }

  public changeEmailPassword(data: any): Observable<any> {
    return this.reqS.post(this.endpoint + 'ChangeEmailPasswordForSelf', data);
  }

  public updatePersonalInfo(data: any): Observable<any> {
    return this.reqS.post(this.endpoint + 'UpdateSelf', data);
  }
  public getUserProfile(): Observable<UserProfile> {
    return this.reqS.get<UserProfile>(this.endpoint + 'UserDetails');
  }

  public getCompanyInfo(cui: string): Observable<CompanyInfo> {
    const urlOptions = {
      params: {
        fiscalCode: cui,
      },
    };
    return this.reqS.get<CompanyInfo>(this.companyEndpoint, urlOptions);
  }
  logout() {
    this.tokenSubscription.unsubscribe();
    this.storageS.clearStorage();
    this.dialog.closeAll();
  }
  getLoggedinUseData(): Observable<{
    init: boolean;
    data: LoginUserI;
  }> {
    return this.user$.pipe(
      filter((val: any) => val && val.hasOwnProperty('init') && val.init),
      distinctUntilChanged(),
    );
  }
  getRouteRoleMatch(roles: string[]) {
    return (
      this.loggedInUserRolesArray.filter(
        (v: string) => roles.indexOf(v) !== -1,
      )[0] || ''
    );
  }
  doesLoggedinUserHasAccessBaseOnPassInRoles(roles: string[]) {
    return this.loggedInUserRolesArray.some(
      (v: string) => roles.indexOf(v) !== -1,
    );
  }
  // returns an array that contains the role names for the logged in user
  get loggedInUserRolesArray(): string[] {
    let rolesArray = [];
    this.user$.getValue()?.data?.roleDetails.forEach((role) => {
      rolesArray.push(role.roleName);
    });
    return rolesArray;
  }
  get loggedInUserId(): string {
    return this.user$.getValue()?.data?.id || '';
  }
  get isAdmin(): boolean {
    return this.loggedInUserRolesArray.includes(Roles.ADMIN);
  }
  get isPharma(): boolean {
    return this.loggedInUserRolesArray.includes(Roles.PHARMA);
  }
  get isPharmacyowner(): boolean {
    return this.loggedInUserRolesArray.includes(Roles.PHARMACY_OWNER);
  }

  getAppCurrentVersion(): Observable<string> {
    return this.reqS
      .get('assets/version.json')
      .pipe(map((v: any) => v.version));
  }

  get isManufacturerOwner(): boolean {
    return this.loggedInUserRolesArray.includes(Roles.MANUFACTURER_OWNER);
  }
  get isManufacturer(): boolean {
    return this.loggedInUserRolesArray.includes(Roles.MANUFACTURER);
  }

  public verifyPhoneNumber(code: string, phoneNumber: string) {
    const payload = {
      phoneNumber: phoneNumber,
      code: code,
    };
    return this.reqS.post<LoginResponse>(
      this.endpoint + 'VerifyPhoneNumber',
      payload,
    );
  }

  public savePhoneNumber(phoneNumber: string) {
    const payload = {
      phoneNumber: phoneNumber,
    };
    return this.reqS.post<LoginResponse>(
      this.endpoint + `SavePhoneNumber`,
      payload,
    );
  }
  get loggedInUserGroupApproveStatus(): any {
    return this.user$.getValue()?.data?.groupApprovalStatus;
  }
  get isLoggedinUserGroupApproved(): boolean {
    return this.isAdmin
      ? true
      : this.loggedInUserGroupApproveStatus === AssociationStatus.Accepted;
  }
  public authorizeEmail(email: string, verificationToken: string) {
    return this.reqS.post(this.endpoint + `AuthorizeEmail`, {
      email,
      verificationToken,
    });
  }
  public resendEmailActivation(email: string, emailActivationLink: string) {
    return this.reqS.post(this.endpoint + 'ResendEmailActivation', {
      email,
      emailActivationLink,
    });
  }
  get isAnyOfPharama(): boolean {
    return this.isPharma || this.isPharmacyowner;
  }
  get isLoggedinUserEmailConfirmed(): boolean {
    return this.user$.getValue()?.data?.emailConfirmed;
  }
}
