import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormBuilder,
  FormGroup,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { InputConfig } from '@app/shared/models';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: InputComponent,
      multi: true,
    },
  ],
})
export class InputComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @Input() config?: InputConfig;
  @ViewChild('input', { static: true }) input?: ElementRef<any>;

  form: FormGroup;
  subscriptions: Subscription[] = [];
  svgClass = 'h-6 w-6';

  onChange: any = () => {};
  onTouched: any = () => {};

  get value() {
    return this.form.value;
  }

  set value(value: any) {
    this.form.setValue(value);
    this.onChange(value);
    this.onTouched();
  }

  constructor(private fb: FormBuilder) {
    this.form = this.fb.group({
      input: [],
    });
    this.subscriptions.push(
      this.form.valueChanges.subscribe((_) => {
        this.onChange(this.getFieldValue());
        this.onTouched();
      }),
    );
  }

  ngOnInit() {
    if (this.config?.value) {
      this.writeValue(this.config.value);
    }
    const el = this.input?.nativeElement;
    if (el != null && this.config != null) {
      Object.keys(this.config).forEach((key) => {
        el[key] = this.config![key];
      });
    }
    if (this.config?.isDisabled) {
      this.form.disable();
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  getFieldValue() {
    const field = this.form.get('input');
    return field ? field.value : null;
  }

  writeValue(value: any) {
    this.form.setValue({ input: value });
    this.form.updateValueAndValidity();
    if (value === null) {
      this.form.reset();
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  clearInput() {
    this.writeValue('');
  }
  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.form.disable({ emitEvent: true });
    } else {
      this.form.enable({ emitEvent: true });
    }
  }
  get isTextAreaType() {
    return (
      this.config?.inputDesignType &&
      this.config?.inputDesignType === 'textarea'
    );
  }
}
