<div
  class="input"
  [ngClass]="[
    config?.cssClasses ? config?.cssClasses : '',
    form.disabled ? 'input-disabled' : ''
  ]"
  [formGroup]="form"
>
  <ng-container *ngIf="!isTextAreaType">
    <div class="input-icon-wrapper" [ngClass]="{ 'no-label': !config?.label }">
      <input
        #input
        formControlName="input"
        [placeholder]="config?.placeholder || ''"
        [type]="config?.type || 'text'"
        [readonly]="config?.isReadonly"
        [min]="config?.min"
      />
      <svg-icon
        [svgClass]="svgClass"
        [name]="config?.iconSrc"
        *ngIf="config?.iconSrc"
      ></svg-icon>
    </div>
    <div>
      <label class="input-label" [for]="config?.id" *ngIf="config?.label">{{
        config?.label
      }}</label>
      <span class="description" *ngIf="config?.description">{{
        config?.description
      }}</span>
    </div>
  </ng-container>
  <ng-container *ngIf="isTextAreaType">
    <textarea name="" id="textarea" formControlName="input"></textarea>
    <label
      class="input-label"
      [for]="config?.id"
      *ngIf="config?.label"
      [ngClass]="[form.invalid && form.dirty ? '!text-error' : '!text-black']"
      >{{ config?.label }}</label
    >
  </ng-container>
</div>
