import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { AngularSvgIconPreloaderModule } from 'angular-svg-icon-preloader';

import { OverlayModule } from '@angular/cdk/overlay';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { JWT_OPTIONS, JwtHelperService } from '@auth0/angular-jwt';
import { environment } from '@environments/environment';
import {
  RECAPTCHA_LANGUAGE,
  RECAPTCHA_V3_SITE_KEY,
  RecaptchaV3Module,
} from 'ng-recaptcha';
import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AcceptLanguageInterceptor } from './core/interceptors/accept-lang/accept-lang.interceptor';
import { AuthInterceptor } from './core/interceptors/auth/auth.interceptor';
import { NotificationModalComponent } from './core/services/notification/notification-modal/notification-modal.component';
import { ResetModule } from './reset-password/reset.module';
import { SharedModule } from './shared/shared.module';
import { DialogModule } from '@angular/cdk-experimental/dialog';

@NgModule({
  declarations: [AppComponent, NotificationModalComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    AngularSvgIconModule.forRoot(),
    AngularSvgIconPreloaderModule.forRoot({
      configUrl: 'assets/json/icons.json',
    }),
    SharedModule,
    CdkStepperModule,
    OverlayModule,
    DialogModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    RecaptchaV3Module,
    ResetModule,
  ],
  providers: [
    Title,
    HttpClient,
    JwtHelperService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AcceptLanguageInterceptor,
      multi: true,
    },
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.reCaptchaSiteKey,
    },
    {
      provide: RECAPTCHA_LANGUAGE,
      useValue: 'ro', // use romania language
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
