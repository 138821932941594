import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AuthService } from '@app/core/services/auth/auth.service';
import { AppRoute } from '@app/shared/models';
import { MenuService } from 'src/app/core/services/menu/menu.service';
import { Pages, showMenuIfThesePaths } from 'src/app/shared/data/routes';
@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
})
export class SideMenuComponent implements OnInit {
  isMobile = false;
  mobileShowMenu = false;
  showMenu = false;
  menuLinks: AppRoute[] = [];

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    if (window.innerWidth < 760) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  }

  get currentVersion() {
    return this.menuS.currentVersion;
  }

  constructor(
    private readonly menuS: MenuService,
    private router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private authS: AuthService,
  ) {
    this.isMobile = window.innerWidth < 760;
    this.activatedRoute.url.subscribe((s) => {
      // this.showMenu = showMenuIfThesePaths.includes(window.location.pathname);
      this.menuS.showMenu = this.showMenuForPath(window.location.pathname);
      this.menuS.showMenuObservable.subscribe((value) => {
        this.showMenu = value;
      });
    });
  }
  toggleMobileMenu(): void {
    this.mobileShowMenu = !this.mobileShowMenu;
  }
  closeMobileMenu() {
    if (this.isMobile) {
      this.mobileShowMenu = false;
    }
  }
  async ngOnInit() {
    this.router.events.subscribe(async (event) => {
      if (event instanceof NavigationEnd) {
        const { url } = event;
        // this.menuS.showMenu = showMenuIfThesePaths.includes(url);
        this.menuS.showMenu = this.showMenuForPath(url);
        this.menuS.showMenuObservable.subscribe((value) => {
          this.showMenu = value;
        });
      }
    });
  }
  /*
   * @para {url: string}
   * Refactor: for url with query params or params e.g /drug/edit/1
   * there is need to check if the url is in the array of showMenuIfThesePaths
   * if it is, then show the menu
   */
  showMenuForPath(url: string): boolean {
    const role = this.authS.getRouteRoleMatch(Pages[0].roles);
    this.menuLinks = Pages.filter((route: AppRoute) => {
      return (
        !route?.roles ||
        this.authS.doesLoggedinUserHasAccessBaseOnPassInRoles(route?.roles)
      );
    }).map((v: AppRoute) => ({
      ...v,
      routerLink: `${v.routerLink}/${v?.rolesRoute?.[role] || ''}`,
    }));
    return showMenuIfThesePaths.some((path) => url.indexOf(path) !== -1);
  }
  get isUserGroupApproved(): boolean {
    return this.authS.isLoggedinUserGroupApproved;
  }
}
