import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SideMenuComponent } from './components/side-menu/side-menu.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { RouterModule } from '@angular/router';
import { InputComponent } from './components/input/input.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { SelectComponent } from './components/select/select.component';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { StepperComponent } from './components/stepper/stepper.component';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { RadioComponent } from './components/radio/radio.component';
import { OptionsPipePipe } from './pipes/options-pipe/options-pipe.pipe';
import { AlertModalComponent } from './components/alert-modal/alert-modal.component';
import { PrescriptionStatusChipComponent } from './components/prescription-status-chip/prescription-status-chip.component';
import { CouponStatusChipComponent } from './components/coupon-status-chip/coupon-status-chip.component';

@NgModule({
  declarations: [
    SideMenuComponent,
    InputComponent,
    CheckboxComponent,
    SelectComponent,
    DatePickerComponent,
    StepperComponent,
    RadioComponent,
    OptionsPipePipe,
    AlertModalComponent,
    PrescriptionStatusChipComponent,
    CouponStatusChipComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    AngularSvgIconModule,
    ReactiveFormsModule,
    FormsModule,
    CdkStepperModule,
  ],
  exports: [
    SideMenuComponent,
    InputComponent,
    CheckboxComponent,
    SelectComponent,
    DatePickerComponent,
    StepperComponent,
    RadioComponent,
    OptionsPipePipe,
    AlertModalComponent,
    PrescriptionStatusChipComponent,
    CouponStatusChipComponent,
  ],
})
export class SharedModule {}
