import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { NotificationModalComponent } from './notification-modal/notification-modal.component';
import { OverlayOptions } from '@app/shared/models';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private _overlayOptions: OverlayOptions;
  private overlayRef: OverlayRef;

  get overlayOptions() {
    return this._overlayOptions;
  }
  private set overlayOptions(value: OverlayOptions) {
    this._overlayOptions = value;
  }

  constructor(private toastr: ToastrService, private overlay: Overlay) {}

  success(message: string): void {
    this.toastr.success(message);
  }

  info(message: string): void {
    this.toastr.info(message);
  }

  warning(message: string): void {
    this.toastr.warning(message);
  }

  error(message: string): void {
    this.toastr.error(message);
  }

  public createOverlay(options: OverlayOptions) {
    this.overlayOptions = options;
    this.overlayRef = this.overlay.create({
      height: '100vh',
      width: '100vw',
      disposeOnNavigation: true,
    });
    const portal = new ComponentPortal(NotificationModalComponent);
    this.overlayRef.attach(portal);
  }

  dismissOverlay() {
    if (this.overlayRef) {
      this.overlayRef.dispose();
    }
  }
}
