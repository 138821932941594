import { unsubscriberHelper } from './core/helpers/unsubscribe.helper';
import { Component, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter, Subscription } from 'rxjs';
import { MenuService } from './core/services/menu/menu.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnDestroy {
  routerEvent$: Subscription;
  appHidMenuForRoute = false;
  currentYear: number;

  constructor(private router: Router, private menuS: MenuService) {
    this.routerEvent$ = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        if (['/multumim'].includes(event.urlAfterRedirects)) {
          this.appHidMenuForRoute = true;
        } else {
          this.appHidMenuForRoute = false;
        }
      });
    this.currentYear = this.getYear();
  }
  get appCurrentVersion() {
    return this.menuS.currentVersion;
  }
  get showMenu() {
    return this.menuS.showMenu;
  }
  public getYear(): number {
    const data = new Date().getFullYear();
    return data;
  }
  ngOnDestroy(): void {
    unsubscriberHelper(this.routerEvent$);
  }
}
