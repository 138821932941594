<div class="container-fluid">
  <div class="w-full flex flex-row gap-0">
    <div
      class="w-full md:w-4/12 lg:w-6/12 hidden md:flex md:flex-row md:justify-center md:items-center bg-image h-screen"
    >
      <svg-icon name="logo" svgClass="optimerix-logo "></svg-icon>
    </div>
    <div
      class="w-full space-x-24 md:w-8/12 lg:w-6/12 h-screen flex flex-col justify-center items-center"
    >
      <div class="flex flex-col w-full max-w-xl items-center">
        <svg-icon
          name="logo"
          svgClass="w-[65px] h-[65px] md:hidden mb-12"
        ></svg-icon>
        <p
          class="font-alternate text-[35px] leading-[46px] tracking-[0.1px] text-black self-center mb-12"
          i18n="@@changePasswordText"
        >
          Schimbare parolă
        </p>
        <form
          [formGroup]="resetPasswordForm"
          class="flex flex-col px-2 md:mx-24"
        >
          <div
            class="font-primary text-[15px] leading-[24px] tracking-[0.1px] self-start text-black mb-4 text-center"
          >
            <span i18n="@@resetPasswordNotificationText"
              >Ești sigur că dorești să resetezi parola pentru adresa</span
            >
            {{ email }}?
          </div>
          <div class="flex flex-col gap-2">
            <div>
              <app-input
                formControlName="parola"
                [config]="passwordConfig"
              ></app-input>
              <p
                [ngClass]="{ invisible: !doesNotMatchCharacterRequirements }"
                class="font-primary font-normal text-xxs text-black-light my-2"
                i18n="@@passwordCharacterValidityText"
              >
                Parola trebuie sa contina minim 8 caractere între care o
                majusculă și o cifră.
              </p>
            </div>
            <div>
              <app-input
                formControlName="confirmaParola"
                [config]="cPasswordConfig"
              ></app-input>
              <p
                [ngClass]="{ invisible: !passwordDidntMatch }"
                class="font-primary font-normal text-xxs text-black-light my-2"
                i18n="@@passwordsMustMatchText"
              >
                Parolele trebuie să coincidă.
              </p>
            </div>
            <div class="flex flex-col items-center">
              <button
                type="submit"
                [disabled]="busy || !resetPasswordForm?.valid"
                (click)="resetPassword()"
                class="btn btn-primary font-primary text-[18px] w-full leading-[24px] tracking-[0.1px] mb-5"
              >
                <span *ngIf="!busy" i18n="@@resetPasswordText"
                  >Resetează parola</span
                >
                <app-loader size="sm" color="white" *ngIf="busy"></app-loader>
              </button>
              <div
                class="font-primary text-[15px] leading-[20px] tracking-[0.1px] self-center cursor-pointer text-gray-dark"
                (click)="navigateToLogin()"
                i18n="@@loginAuthText"
              >
                Autentificare
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
