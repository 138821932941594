import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LanguagesService } from '@app/core/services/languages/languages.service';
import { Observable } from 'rxjs';

@Injectable()
export class AcceptLanguageInterceptor implements HttpInterceptor {
  constructor(private langS: LanguagesService) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    const headers = req.headers.set(
      'Accept-Language',
      this.langS.backEndLanguage,
    );
    const authReq = req.clone({ headers });
    return next.handle(authReq);
  }
}
