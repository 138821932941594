import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OverlayOptions } from '@app/shared/models';
import { NotificationService } from '../notification.service';

@Component({
  selector: 'app-notification-modal',
  templateUrl: './notification-modal.component.html',
  styleUrls: ['./notification-modal.component.scss'],
})
export class NotificationModalComponent implements OnInit {
  options: OverlayOptions;
  iconName = '';

  constructor(
    private readonly nofificationS: NotificationService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.options = this.nofificationS.overlayOptions;
    this.iconName = this.options?.icon
      ? this.options?.icon
      : this.options.status === 'success'
      ? 'check'
      : this.options.status === 'failure'
      ? 'close'
      : '';
  }

  dismissOverlay() {
    this.nofificationS.dismissOverlay();
  }
  buttonWithAction(cb: () => void) {
    cb();
    this.nofificationS.dismissOverlay();
  }
}
