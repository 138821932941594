import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { RadioConfig } from '@app/shared/models';

type LangT = 'ro' | 'en' | 'de' | 'hu';
@Injectable({
  providedIn: 'root',
})
export class LanguagesService {
  langStorageKey = 'locale';
  backEndTranslationlang$: BehaviorSubject<string> = new BehaviorSubject('');
  appLang$: BehaviorSubject<string> = new BehaviorSubject('');
  langRadioSelectionConfigList: RadioConfig[] = [
    {
      id: 'ro',
      label: $localize`:@@romania:Română`,
    },
    {
      id: 'de',
      label: $localize`:@@german:Germană`,
    },
    {
      id: 'en',
      label: $localize`:@@english:Engleză`,
    },
    {
      id: 'hu',
      label: $localize`:@@Hugarian:Maghiară`,
    },
  ];
  backendLang = {
    de: 'de-DE',
    en: 'en-US',
    ro: 'ro-RO',
    hu: 'hu-HU',
  };
  appRouteLang = {
    de: 'de',
    en: 'en-gb',
    ro: 'ro',
    hu: 'hu',
  };
  constructor() {
    let lang = '';
    try {
      lang = localStorage.getItem(this.langStorageKey);
      lang ||= 'ro';
    } catch (e) {
      lang = 'ro';
    }
    this.appLang$.next(lang);
    this.backEndTranslationlang$.next(
      this.backendLang[lang] || this.backendLang.ro,
    );

    let dontRedirect =
      window.location?.port && window.location?.port === '4200';
    if (
      !dontRedirect &&
      !window.location.pathname.split('/').includes(this.appRouteLang[lang])
    ) {
      this.routeToAppLangApp();
    }
  }
  get langList() {
    return this.langRadioSelectionConfigList.map((v) => ({
      ...v,
      action: () => {
        this.saveLang(v.id as LangT);
      },
    }));
  }
  get lang() {
    return this.appLang$.getValue();
  }
  get backEndLanguage(): string {
    return this.backEndTranslationlang$.getValue();
  }
  get appLanguage(): string {
    return window.location?.port && window.location?.port === '4200'
      ? ''
      : this.appRouteLang[this.appLang$.getValue()];
  }
  routeToAppLangApp() {
    window.location.replace(
      [
        new URL(window.location.href).origin,
        this.appLanguage,
        'index.html',
      ].join('/'),
    );
  }
  saveLang(lang: LangT) {
    this.backEndTranslationlang$.next(this.backendLang[lang]);
    localStorage.setItem(this.langStorageKey, lang);
    this.appLang$.next(lang);
    this.routeToAppLangApp();
  }
}
