import { ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { LoaderModule } from '@app/module/loader/loader.module';
import { ResetComponent } from './reset.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { AngularSvgIconPreloaderModule } from 'angular-svg-icon-preloader';
import { SharedModule } from '@app/shared/shared.module';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: ResetComponent,
      },
    ],
  },
];

@NgModule({
  declarations: [ResetComponent],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild(routes),
    LoaderModule,
    ReactiveFormsModule,
    AngularSvgIconModule.forRoot(),
    AngularSvgIconPreloaderModule.forRoot({
      configUrl: 'assets/json/icons.json',
    }),
  ],
})
export class ResetModule {}
