<div class="w-full h-full flex flex-col justify-center items-center text-center px-4 bg-white">
  <div class="mb-6">
    <svg-icon [name]="iconName" svgClass="h-20 w-20 md:h-32 md:w-32 text-primary"></svg-icon>
  </div>
  <div class="mt-6 mb-4">
    <h1 class="font-alternate text-4xl text-black"> {{ options.title }} </h1>
  </div>
  <div class="mb-6 max-w-md" [ngClass]="[options?.messageDivCssClasses || '']">
    <p class="text-lg tracking-[0.1px] text-black"> {{ options.message }} </p>
  </div>
  <div class="mb-6 max-w-md" *ngIf="options?.subMessage?.text" [ngClass]="[options?.subMessage?.cssClasses || '']">
    <p class="text-lg tracking-[0.1px] text-black"> {{ options?.subMessage?.text }} </p>
  </div>
  <div class="mt-6 w-full max-w-xl flex flex-col md:flex-row items-center justify-center gap-[16px]"
    [ngClass]="[options.actionsDivCssClasses || '']">
    <button *ngIf="options?.callBackAction" [ngClass]="[options.callBackAction?.cssClasses || '']"
      class="btn !uppercase !h-[58px] bg-gray-light border-0 w-full md:!w-[245px]"
      (click)="options.callBackAction.callback()"> {{ options.callBackAction.text }} </button>
    <button *ngIf="options?.secondaryAction" [ngClass]="[options.secondaryAction?.cssClasses || '']"
      class="btn !uppercase !h-[58px] bg-gray-light !border-0 w-full md:!w-[245px]" (click)="dismissOverlay()"
      [routerLink]="options.secondaryAction.url"> {{ options.secondaryAction.text }} </button>
    <button *ngIf="options?.action" class="btn btn-primary w-full md:!w-[245px] !h-[58px]"
      [ngClass]="[options.action?.cssClasses || '']" (click)="dismissOverlay()" [routerLink]="options.action.url">
      <span> {{ options.action.text }} </span>
      <span class="ml-[6px]">
        <svg-icon svgClass="w-[24px] h-[24px] text-white" name="play"></svg-icon>
      </span>
    </button>
  </div>
</div>
