import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth/auth.guard';
import { SideMenuComponent } from './shared/components/side-menu/side-menu.component';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () =>
      import('./login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'register',
    loadChildren: () =>
      import('./register/register.module').then((m) => m.RegisterModule),
  },
  {
    path: 'verificare',
    loadChildren: () =>
      import('./verificare-esuata/verificare-esuata.module').then(
        (m) => m.VerificareEsuataModule,
      ),
  },
  {
    path: 'reset',
    loadChildren: () =>
      import('./reset-password/reset.module').then((v) => v.ResetModule),
  },
  {
    path: 'redirect',
    loadChildren: () =>
      import('./redirect/redirect.module').then((v) => v.RedirectModule),
  },
  {
    path: 'multumim',
    loadChildren: () =>
      import('./multumim/multumim.module').then((m) => m.MultumimModule),
  },
  {
    path: 'payment-callback',
    loadChildren: () =>
      import('./payment-callback/payment-callback-routing.module').then(
        (m) => m.PaymentCallbackRoutingModule,
      ),
  },
  {
    canActivate: [AuthGuard],
    path: '',
    component: SideMenuComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: '/pharmacies/admin',
      },
      {
        path: 'pharmacies',
        loadChildren: () =>
          import('./pharmacy/pharmacies.module').then(
            (m) => m.PharmaciesModule,
          ),
      },
      {
        path: 'subscription',
        loadChildren: () =>
          import('./subscription/subscription.module').then(
            (m) => m.SubscriptionModule,
          ),
      },
      {
        path: 'settings',
        loadChildren: () =>
          import('./settings/settings.module').then((m) => m.SettingsModule),
      },
      {
        path: 'drugs',
        loadChildren: () =>
          import('./medicamente/medicamente.module').then(
            (m) => m.MedicamenteModule,
          ),
      },
      {
        path: 'account-management',
        loadChildren: () =>
          import('./users/users.module').then((m) => m.UsersModule),
      },
      {
        path: 'campaign',
        loadChildren: () =>
          import('./campaign/campaign.module').then((m) => m.CampaignModule),
      },
      {
        path: 'producatori',
        loadChildren: () =>
          import('./producatori/producatori.module').then(
            (m) => m.ProducatoriModule,
          ),
      },
      {
        path: 'all-orders',
        loadChildren: () =>
          import('./all-orders/all-orders.module').then(
            (m) => m.AllOrdersModule,
          ),
      },
      {
        path: 'my-orders',
        loadChildren: () =>
          import('./my-orders/my-orders.module').then((m) => m.MyOrdersModule),
      },
      {
        path: 'issued-discounts',
        loadChildren: () =>
          import('./issued-discounts/issued-discounts.module').then(
            (m) => m.IssuedDiscountsModule,
          ),
      },
      {
        path: 'my-invoices',
        loadChildren: () =>
          import('./my-invoices/my-invoices.module').then(
            (m) => m.MyInvoicesModule,
          ),
      },
      {
        path: 'all-invoices',
        loadChildren: () =>
          import('./all-invoices/all-invoices.module').then(
            (m) => m.AllInvoicesModule,
          ),
      },
      {
        path: 'mobile',
        loadChildren: () =>
          import('./mobile/mobile.module').then((m) => m.MobileModule),
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
