import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent {
  @Input() color: 'primary' | 'secondary' | 'white';
  @Input() size: 'lg' | 'sm';
  constructor() {}
  get loaderColor(): string {
    return typeof this.color !== 'undefined' ? this.color : 'primary';
  }
  get loaderSize(): string {
    return typeof this.size !== 'undefined' ? this.size : 'lg';
  }
}
