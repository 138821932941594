import { unsubscriberHelper } from '@app/core/helpers/unsubscribe.helper';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@app/core/services/auth/auth.service';
import { NotificationService } from '@app/core/services/notification/notification.service';
import { PasswordValidator } from '@app/core/validators/password-validator/password-validator';
import { InputConfig } from '@app/shared/models';
import { of, Subscription, switchMap } from 'rxjs';
import { ReCaptchaService } from '@app/core/services/reCaptcha/re-captcha.service';

@Component({
  selector: 'app-reset-component',
  templateUrl: './reset.component.html',
})
export class ResetComponent implements OnInit, OnDestroy {
  busy = false;
  passwordConfig: InputConfig = {
    id: 'password',
    label: $localize`:@@password:Parola`,
    type: 'password',
    cssClasses: '!m-0',
  };
  cPasswordConfig: InputConfig = {
    id: 'confirma-parola',
    label: $localize`:@@confirmPassword:Confirmă parola`,
    type: 'password',
    cssClasses: '!m-0',
    placeholder: '',
  };
  resetPasswordForm: FormGroup = this.fb.group(
    {
      parola: [
        '',
        [
          Validators.required,
          Validators.minLength(8),
          PasswordValidator.uppercaseCharacterRule(1),
          PasswordValidator.digitCharacterRule(1),
        ],
      ],
      confirmaParola: ['', [Validators.required]],
    },
    {
      validator: PasswordValidator.mismatchedPasswords(
        'parola',
        'confirmaParola',
      ),
    },
  );
  route$: Subscription;
  resetPassword$: Subscription;
  token: string = '';
  email: string = '';

  constructor(
    private fb: FormBuilder,
    private readonly authS: AuthService,
    private router: Router,
    private notificationS: NotificationService,
    private aRoute: ActivatedRoute,
    private reCaptchaService: ReCaptchaService,
  ) {}
  ngOnDestroy(): void {
    unsubscriberHelper(this.resetPassword$);
    unsubscriberHelper(this.route$);
  }
  ngOnInit(): void {
    this.route$ = this.aRoute.queryParams.subscribe({
      next: (params) => {
        this.token = params?.['token']
          ? params?.['token'].replace(/ /g, '+')
          : '';
        this.email = params?.['email']
          ? params?.['email'].replace(/ /g, '+')
          : '';

        if (this.email === '' || this.token === '') {
          this.navigateToLogin();
        }
      },
      error: (_err) => {
        this.navigateToLogin();
      },
    });
  }

  navigateToLogin() {
    this.router.navigate(['/login']);
  }
  get passwordDidntMatch(): boolean {
    return (
      this.isFormFieldHasAnyError('confirmaParola') &&
      this.resetPasswordForm.controls?.['confirmaParola']?.errors[
        'mismatchedPasswords'
      ]
    );
  }
  get doesNotMatchCharacterRequirements(): boolean {
    return (
      this.isFormFieldHasAnyError('parola') &&
      (this.resetPasswordForm.controls?.['parola']?.errors['minlength'] ||
        this.resetPasswordForm.controls?.['parola']?.errors[
          'digitCharacterRule'
        ] ||
        this.resetPasswordForm.controls?.['parola']?.errors[
          'uppercaseCharacterRule'
        ])
    );
  }
  isFormFieldHasAnyError(formFieldName: string) {
    return (
      this.resetPasswordForm.controls?.[formFieldName]?.touched &&
      this.resetPasswordForm.controls?.[formFieldName]?.dirty &&
      this.resetPasswordForm.controls?.[formFieldName]?.errors
    );
  }
  showNotificationModal() {
    this.notificationS.createOverlay({
      title: $localize`:@@parolaAFostResetata:Parola a fost resetată`,
      message: $localize`:@@passwordResetSuccessfulText:Parola ta a fost resetată. Apasă butonul de mai jos ca mergi în pagina de autentificare.`,
      status: 'success',
      secondaryAction: {
        text: 'Autentificare',
        url: '/login',
        cssClasses: 'btn-secondary !w-[312px] !h-[58px]',
      },
    });
  }
  resetPassword() {
    if (this.email && this.token && this.resetPasswordForm.valid) {
      this.busy = true;
      this.resetPassword$ = this.reCaptchaService
        .reCaptcha()
        .pipe(
          switchMap((v) => {
            if (v) {
              return this.authS.resetPassword({
                email: this.email,
                password: this.resetPasswordForm.getRawValue()?.parola,
                token: this.token,
              });
            } else {
              return of(null);
            }
          }),
        )
        .subscribe({
          next: (_resp: any) => {
            this.busy = false;
            this.showNotificationModal();
          },
          error: (err) => {
            this.busy = false;
            this.notificationS.error(
              err?.error?.message ||
                $localize`:@@resetPasswordErrorText:A apărut o eroare în timp ce încercați să resetați parola`,
            );
          },
        });
    }
  }
}
