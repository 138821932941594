<div class="menu-container" [ngClass]="{ hidden: !showMenu }" *ngIf="showMenu">
  <div class="pt-2 px-2 md:pb-8 flex justify-between md:justify-center items-center"
    [ngClass]="{'pb-8': mobileShowMenu}">
    <svg-icon name="logomark" svgClass="h-12 w-12 text-primary"></svg-icon>
    <button type="button" (click)="toggleMobileMenu()" class="md:hidden">
      <svg-icon name="close" svgClass="h-[24px] w-[24px] text-gray-dark" *ngIf="mobileShowMenu"></svg-icon>
      <svg-icon name="hamburger-menu" svgClass="h-[34px] w-[34px] text-gray-dark" *ngIf="!mobileShowMenu"></svg-icon>
    </button>
  </div>
  <div [ngClass]="{'hidden md:block': !mobileShowMenu, 'block': mobileShowMenu}">
    <div>
      <ng-container *ngIf="isUserGroupApproved">
        <div *ngFor="let menu of menuLinks" class="menu-item">
          <a [routerLink]="menu?.routerLink" routerLinkActive="active-link" (click)="closeMobileMenu()">
            <svg-icon svgClass="h-8 w-8 mr-2" [name]="menu?.iconSrc"></svg-icon>
            <span class="font-medium text-xs leading-4 whitespace-normal">{{ menu?.title }}</span>
          </a>
        </div>
      </ng-container>
    </div>
    <div class="md:absolute md:bottom-0">
      <div class="menu-item mt-auto">
        <a [routerLink]="['/settings']" routerLinkActive="active-link" (click)="closeMobileMenu()">
          <svg-icon svgClass="h-8 w-8 mr-2" name="settings"></svg-icon>
          <span class="font-medium text-xs leading-4" i18n="@@setari">Setari</span>
        </a>
      </div>
      <div class="font-medium text-xs leading-4 whitespace-normal p-4"> <span i18n="@@versiune">Versiune</span> {{ currentVersion }}</div>
    </div>
  </div>
</div>
<!-- min-h-screen -->
<div class="w-full relative !has-background overflow-auto"
  [ngClass]="[showMenu ? 'md:pl-28' : 'pl-0',isMobile ? 'pt-[130px]' : '']" id="app">
  <router-outlet></router-outlet>
</div>
<app-alert-modal></app-alert-modal>
