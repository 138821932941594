import { Component, OnDestroy, OnInit } from '@angular/core';
import { unsubscriberHelper } from '@app/core/helpers/unsubscribe.helper';
import { AlertModalI } from '@app/shared/models/api/alert.interface';
import { AlertService } from '@app/core/services/alert/alert.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-alert-modal',
  templateUrl: './alert-modal.component.html',
  styleUrls: ['./alert-modal.component.scss'],
})
export class AlertModalComponent implements OnInit, OnDestroy {
  alertModalData: AlertModalI;

  alertSub$: Subscription;
  btnTypes = {
    primary: 'btn btn-primary',
    secondary: 'btn btn-secondary',
    tertiary: 'btn btn-tertiary',
  };
  constructor(private alertS: AlertService) {}
  ngOnInit(): void {
    this.alertSub$ = this.alertS.alertData$.subscribe({
      next: (value: AlertModalI) => {
        this.alertModalData = value;
      },
    });
  }
  get showAlert(): boolean {
    return (
      this.alertModalData && Object.entries(this.alertModalData).length > 0
    );
  }
  getBtnClasses(type = 'primary'): string {
    return this.btnTypes[type];
  }
  runCallBack(cb: () => void): void {
    if (typeof cb === 'function') {
      cb();
    }
  }
  ngOnDestroy(): void {
    unsubscriberHelper(this.alertSub$);
  }
}
