<div class="app">
  <router-outlet></router-outlet>
  <div *ngIf="!appHidMenuForRoute"
    class="w-full md:w-max h-max md:h-[30px] bg-white shadow-campaignCardBoxShadow static md:fixed bottom-0 z-[999] rounded-tr-2 rounded-tl-2 flex flex-col md:flex-row md:items-center md:justify-center gap-[25px] p-2 md:py-0 md:px-[16px] overflow-hidden"
    [ngClass]="[showMenu ? 'md:right-12' : 'left-2']">
    <span class="text-xxs font-[500] font-primary text-black">©{{ currentYear }}
      <span i18n="@@copyRight">Inside eRX SRL. Toate drepturile sunt rezervate.</span></span>
    <div class="flex flex-row gap-[25px]">
      <a href="https://optimerix.ro/termeni-conditii" target="_blank" class="text-black !text-xxs font-primary">
        <span i18n="@@termsAndConditions">Termenii și Condițiile</span>
      </a>
      <a href="https://optimerix.ro/politica-confidentialitate.html" target="_blank"
        class="text-black !font-primary !text-xxs"> <span i18n="@@privacyPolicyText">Politica de
          Confidențialitate</span></a>
    </div>
    <p class="text-black !font-primary !text-xxs"> <span i18n="@@versiune">Versiune</span> {{ appCurrentVersion }} </p>
  </div>
</div>